<template>
  <div class="navbar-expand-lg nav-light navbar__login-wrapper">
    <div class="container-fluid container-fluid-login">
      <div class="w-100 d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <a class="navbar-brand" @click="$router.back(-1)">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.6666 9.66667V12.3333H5.66663L13 19.6667L11.1066 21.56L0.546631 11L11.1066 0.440002L13 2.33334L5.66663 9.66667H21.6666Z"
                fill="#F2F2F2"
              />
            </svg>
          </a>
          <h6 class="title-navbar-login">
            Register
          </h6>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="card card__form-login">
      <div class="card-body card__body-form">
        <h6 class="title-form">
          Lengkapi Data Donatur
        </h6>
        <form @submit.prevent="handleSubmitRegister" class="needs-validation" novalidate>
          <div class="input-group input__group mb-3">
            <span id="basic-addon1" class="input-group-text input__text-login">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.9599 13.7101C15.8896 13.3722 15.5587 13.1552 15.2206 13.2256C14.8827 13.2959 14.6658 13.6269 14.7362 13.9648C14.7767 14.1595 14.7284 14.359 14.6038 14.5123C14.5316 14.6011 14.3706 14.75 14.1038 14.75H1.89713C1.63032 14.75 1.46935 14.6011 1.39716 14.5123C1.27251 14.359 1.22426 14.1595 1.26479 13.9648C1.91341 10.8494 4.66138 8.57356 7.83479 8.49681C7.88976 8.49894 7.94498 8.5 8.00048 8.5C8.05616 8.5 8.1116 8.49891 8.16679 8.49678C10.4022 8.55041 12.4683 9.68262 13.7195 11.5487C13.9117 11.8354 14.2999 11.9119 14.5867 11.7197C14.8734 11.5275 14.95 11.1393 14.7577 10.8526C13.7322 9.32306 12.243 8.21088 10.5451 7.65203C11.5798 6.87613 12.2505 5.63991 12.2505 4.25C12.2505 1.90656 10.3439 0 8.00048 0C5.65701 0 3.75048 1.90656 3.75048 4.25C3.75048 5.64075 4.42198 6.87762 5.45776 7.65347C4.50876 7.96591 3.61829 8.45206 2.83891 9.09313C1.40979 10.2687 0.416133 11.9083 0.0410387 13.7101C-0.0765238 14.2747 0.06432 14.8545 0.427382 15.301C0.788664 15.7452 1.32438 16 1.89713 16H14.1038C14.6766 16 15.2123 15.7452 15.5735 15.301C15.9367 14.8545 16.0775 14.2747 15.9599 13.7101ZM5.00048 4.25C5.00048 2.59578 6.34626 1.25 8.00048 1.25C9.6547 1.25 11.0005 2.59578 11.0005 4.25C11.0005 5.85328 9.73626 7.16684 8.15232 7.24619C8.10179 7.24525 8.05116 7.24478 8.00048 7.24478C7.95004 7.24478 7.8996 7.24528 7.84923 7.24622C6.26501 7.16719 5.00048 5.8535 5.00048 4.25Z"
                  fill="#828282"
                />
              </svg>
            </span>
            <input
              type="text"
              class="form-control form__input"
              placeholder="Nama Lengkap"
              aria-label="Nama Lengkap"
              v-model="formRegister.fullname"
              @keyup="handleKeyUpFullname"
              aria-describedby="basic-addon1"
            />
          </div>
          <div class="input-group input__group mb-3">
            <span class="input-group-text input__text-login" id="basic-addon1">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.375 8.10938C15.7202 8.10938 16 7.82956 16 7.48438V3.39062C16 2.01213 14.8785 0.890625 13.5 0.890625H2.5C1.1215 0.890625 0 2.01213 0 3.39062V10.6094C0 11.9879 1.1215 13.1094 2.5 13.1094H13.5C14.8785 13.1094 16 11.9879 16 10.6094C16 10.2642 15.7202 9.98438 15.375 9.98438C15.0298 9.98438 14.75 10.2642 14.75 10.6094C14.75 11.2986 14.1892 11.8594 13.5 11.8594H2.5C1.81075 11.8594 1.25 11.2986 1.25 10.6094V3.52306L6.67984 6.89947C7.087 7.15263 7.5435 7.27922 8 7.27922C8.4565 7.27922 8.913 7.15263 9.32016 6.89947L14.75 3.52306V7.48438C14.75 7.82956 15.0298 8.10938 15.375 8.10938ZM8.66006 5.83794C8.25294 6.09109 7.74706 6.09113 7.33994 5.83794L1.77153 2.37538C1.97678 2.22769 2.22841 2.14062 2.5 2.14062H13.5C13.7716 2.14062 14.0232 2.22772 14.2285 2.37541L8.66006 5.83794Z"
                  fill="#828282"
                />
              </svg>
            </span>
            <input
              type="text"
              class="form-control form__input"
              placeholder="Email"
              aria-label="Email"
              aria-describedby="basic-addon1"
              v-model="formRegister.email"
              @keyup="handleKeyupEmail"
            />
            <div class="invalid-feedback d-block">
              {{ messageError.email }}
            </div>
          </div>
          <div class="input-group input__group mb-3">
            <span class="input-group-text input__text-login" id="basic-addon1">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.9987 12.8923C15.9764 12.2734 15.7186 11.6958 15.2728 11.266C14.3999 10.4243 13.6686 9.93845 12.9713 9.73695C12.0105 9.45926 11.1205 9.71176 10.3263 10.4875C10.3251 10.4886 10.3239 10.4898 10.3227 10.491L9.47771 11.3297C8.94961 11.032 7.92274 10.3784 6.8133 9.26892L6.73105 9.18673C5.62143 8.07714 4.96783 7.05008 4.67042 6.52258L5.50917 5.67754C5.51036 5.67636 5.51155 5.67517 5.51274 5.67395C6.28839 4.87976 6.54089 3.98983 6.26324 3.02892C6.06171 2.33164 5.57586 1.60033 4.73417 0.727483C4.30436 0.281764 3.72683 0.0239515 3.10792 0.00163904C2.48864 -0.0207047 1.89439 0.194858 1.43352 0.608545L1.41558 0.62467C1.40727 0.632139 1.39914 0.639858 1.39121 0.647764C0.473738 1.56523 -0.0072 2.84964 0.000331252 4.36217C0.013175 6.93161 1.42533 9.86998 3.7778 12.2224C4.22139 12.666 4.72555 13.105 5.27624 13.5273C5.55014 13.7373 5.94246 13.6855 6.15249 13.4116C6.36255 13.1376 6.31074 12.7453 6.0368 12.5353C5.52946 12.1463 5.0668 11.7436 4.66167 11.3386C2.5368 9.21373 1.26152 6.60342 1.2503 4.35595C1.24452 3.19108 1.59492 2.2188 2.26377 1.54298L2.26855 1.53867C2.72283 1.13089 3.41061 1.1557 3.83442 1.59517C5.45252 3.27323 5.33536 4.06545 4.62036 4.79864L3.4618 5.96589C3.28011 6.14895 3.22952 6.42483 3.33442 6.66045C3.36383 6.72651 4.07536 8.29879 5.84739 10.0708L5.92968 10.153C7.70149 11.9248 9.2738 12.6363 9.33986 12.6657C9.57543 12.7707 9.85136 12.7201 10.0344 12.5384L11.2017 11.3798C11.935 10.6648 12.7272 10.5477 14.4051 12.1658C14.8446 12.5895 14.8694 13.2773 14.4617 13.7315L14.4573 13.7365C13.787 14.3999 12.8251 14.75 11.6729 14.75C11.6634 14.75 11.6539 14.75 11.6444 14.7499C10.7234 14.7453 9.65693 14.4929 8.56033 14.0199C8.24346 13.8831 7.87561 14.0292 7.73892 14.3462C7.60221 14.6632 7.7483 15.0309 8.06527 15.1676C9.33283 15.7144 10.5349 15.9944 11.6381 15.9999C11.6499 16 11.6615 16 11.6732 16C13.1705 16 14.4422 15.5194 15.3525 14.609C15.3605 14.6011 15.3681 14.593 15.3756 14.5847L15.3918 14.5666C15.8055 14.1058 16.021 13.5112 15.9987 12.8923Z"
                  fill="#828282"
                />
              </svg>
            </span>
            <input
              type="text"
              class="form-control form__input"
              placeholder="Nomor Ponsel"
              aria-label="Nomor Ponsel"
              aria-describedby="basic-addon1"
              v-model="formRegister.phone"
              @keyup="handleKeyupPhone"
              @keypress="hanldeKeypressPhone"
            />
            <div class="invalid-feedback d-block">
              {{ messageError.phone }}
            </div>
          </div>
          <div class="input-group input__group mb-3">
            <span class="input-group-text input__text-login" id="basic-addon1">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8274 5.1861C15.4936 4.33629 14.8816 3.11014 13.8613 2.09465C12.8446 1.08265 11.6314 0.488153 10.7927 0.167592C9.87199 -0.184313 8.82572 0.0374989 8.12728 0.732652L5.67298 3.17546C4.94545 3.89955 4.73576 5.01373 5.15117 5.94794C5.27998 6.23769 5.42007 6.5211 5.56976 6.79491L0.183062 12.1816C0.0658435 12.2988 0 12.4578 0 12.6235V15.3735C0 15.7187 0.279811 15.9985 0.624998 15.9985H3.37499C3.72017 15.9985 3.99998 15.7187 3.99998 15.3735V14.2485L5.12498 14.2485C5.47017 14.2485 5.74998 13.9687 5.74998 13.6235V12.4985H6.87497C7.22016 12.4985 7.49997 12.2187 7.49997 11.8735C7.49997 11.5284 7.22016 11.2485 6.87497 11.2485H5.12498C4.77979 11.2485 4.49998 11.5284 4.49998 11.8735V12.9985L3.37499 12.9985C3.0298 12.9985 2.74999 13.2784 2.74999 13.6235V14.7485H1.25V12.8824L6.79232 7.34013C6.99594 7.1365 7.0341 6.82016 6.88475 6.57397C6.66816 6.21697 6.46919 5.83548 6.29341 5.44007C6.08719 4.97629 6.19226 4.42226 6.55485 4.06136L9.00912 1.61859C9.35953 1.26987 9.88446 1.15865 10.3466 1.33521C11.0722 1.61259 12.1181 2.12315 12.9795 2.98058C13.8463 3.8432 14.3739 4.90454 14.6639 5.64298C14.8449 6.10385 14.7349 6.62744 14.3838 6.97694L11.8998 9.44924C11.5381 9.80921 10.9982 9.91446 10.5243 9.71737C10.1335 9.55481 9.75496 9.36168 9.39934 9.1434C9.10518 8.96284 8.72031 9.05496 8.53975 9.34915C8.35919 9.64334 8.45128 10.0282 8.74547 10.2088C9.15646 10.461 9.59346 10.684 10.0443 10.8715C10.9862 11.2633 12.0607 11.0527 12.7816 10.3352L15.2655 7.86294C15.9701 7.16172 16.1906 6.11101 15.8274 5.1861Z"
                  fill="#828282"
                />
              </svg>
            </span>
            <input
              v-model="formRegister.password"
              type="password"
              class="form-control form__input"
              placeholder="Password"
              @keyup="handleKeyUpPassword"
            />
            <div class="invalid-feedback d-block">
              {{ messageError.password }}
            </div>
            <i class="fas fa-eye icon-eye" @click="handleShowPassword" />
          </div>
          <div class="form-group signin-group">
            <button
              type="submit"
              class="btn btn-sm btn-warning login-button w-100"
              :disabled="isDisabledBtnRegister"
            >
              Daftar
            </button>
            <router-link to="/login" class="btn btn-sm btn-light register-button w-100"
              >Sudah Punya Akun</router-link
            >
          </div>
        </form>
      </div>
    </div>

    <div class="form-group social-group">
      <h6 class="title__social">
        atau lebih cepat
      </h6>
      <button
        type="button"
        class="btn btn-sm btn-light btn-login-google w-100"
        @click="handleSignUpGoogle"
      >
        <span class="iconify icon-google" data-icon="mdi:google" data-inline="false" />
        Masuk Dengan Google
      </button>
      <!-- <button
        type="button"
        class="btn btn-sm btn-light btn-login-facebook w-100"
        @click="handleSignUpFacebook"
      >
        <span class="iconify icon-facebook" data-icon="mdi:facebook" data-inline="false" />
        Masuk Dengan Facebook
      </button> -->
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2'

export default {
  data() {
    return {
      formRegister: {
        fullname: null,
        email: null,
        password: null,
        phone: null
      },
      messageError: {
        fullname: null,
        email: null,
        password: null,
        phone: null
      }
    }
  },
  computed: {
    isDisabledBtnRegister() {
      if (
        this.formRegister.fullname == null ||
        this.formRegister.fullname == '' ||
        this.formRegister.email == null ||
        this.formRegister.email == '' ||
        this.formRegister.password == null ||
        this.formRegister.password == '' ||
        this.formRegister.phone == null ||
        this.formRegister.phone == '' ||
        this.messageError.email !== null ||
        this.messageError.password !== null ||
        this.messageError.phone !== null
      ) {
        return true
      }

      return false
    }
  },
  methods: {
    handleShowPassword(event) {
      if (event.target.classList.contains('fa-eye')) {
        event.target.classList.remove('fa-eye')
        event.target.classList.add('fa-eye-slash')
        this.$refs.password.type = 'text'
      } else {
        event.target.classList.remove('fa-eye-slash')
        event.target.classList.add('fa-eye')
        this.$refs.password.type = 'password'
      }
    },
    handleKeyUpFullname($event) {
      const form = document.querySelector('.needs-validation')
      if (this.formRegister.fullname === '' || this.formRegister.fullname === null) {
        this.messageError.fullname = 'Nama lengkap tidak boleh kosong'
        $event.target.required = true
        form.classList.add('was-validated')
      } else {
        this.messageError.fullname = null
        form.classList.remove('was-validated')
        $event.target.required = false
      }
    },
    handleKeyupEmail($event) {
      const formatEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const form = document.querySelector('.needs-validation')

      if (this.formRegister.email === '' || this.formRegister.email === null) {
        this.messageError.email = 'Email tidak boleh kosong'
        $event.target.required = true
        form.classList.add('was-validated')
      } else if (!formatEmail.test($event.target.value)) {
        this.messageError.email = 'Format email tidak valid'
        $event.target.required = true
        form.classList.add('was-validated')
      } else {
        this.messageError.email = null
        form.classList.remove('was-validated')
        $event.target.required = false
      }
    },
    handleKeyUpPassword($event) {
      const form = document.querySelector('.needs-validation')

      if (this.formRegister.password === '' || this.formRegister.password === null) {
        this.messageError.password = 'Password tidak boleh kosong'
        $event.target.required = true
        form.classList.add('was-validated')
      } else if ($event.target.value.length <= 7) {
        this.messageError.password = 'Password kurang dari 8 karakter'
        $event.target.required = true
        form.classList.add('was-validated')
      } else {
        this.messageError.password = null
        form.classList.remove('was-validated')
        $event.target.required = false
      }
    },
    handleKeyupPhone($event) {
      const form = document.querySelector('.needs-validation')
      if (this.formRegister.phone == null || this.formRegister.phone == '') {
        this.messageError.phone = 'Nomor ponsel tidak boleh kosong'
        $event.target.required = true
        form.classList.add('was-validated')
      } else if ($event.target.value.length < 12) {
        this.messageError.phone = 'Nomor ponsel minimal 12 digit'
        $event.target.required = true
        form.classList.add('was-validated')
      } else {
        this.messageError.phone = null
        form.classList.remove('was-validated')
        $event.target.required = false
      }
    },
    hanldeKeypressPhone($event) {
      if ($event.which < 43 || $event.which > 57) {
        $event.preventDefault()
      }
    },
    handleSubmitRegister() {
      const fullName = this.formRegister.fullname.split(/\s+/)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let firstName
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let lastName
      if (fullName.length > 2) {
        firstName = fullName.slice(0, -1).join(' ')
        lastName = fullName.pop()
      } else {
        firstName = fullName[0]
        lastName = null
      }

      swal.fire({
        title: '', // add html attribute if you want or remove
        text: 'Mohon Menunggu',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: function() {
          swal.showLoading()
        }
      })

      this.$store
        .dispatch('account/register', {
          email: this.formRegister.email,
          password: this.formRegister.password,
          // eslint-disable-next-line @typescript-eslint/camelcase
          phone_number: this.formRegister.phone,
          // eslint-disable-next-line @typescript-eslint/camelcase
          first_name: firstName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          last_name: lastName
        })
        .then(() => {
          this.$router
            .push(this.$route.query.redirect !== undefined ? this.$route.query.redirect : '/')
            .then(() => {
              // location.reload()
            })
          swal.close()
          swal.hideLoading()
        })
        .catch(error => {
          swal.close()
          swal.hideLoading()
          if (error.status === 400) {
            swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.data.errors
            })
          }
        })
    },
    handleSignUpGoogle() {
      swal.fire({
        title: '', // add html attribute if you want or remove
        text: 'Mohon Menunggu',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: function() {
          swal.showLoading()
        }
      })
      const GoogleAuth = window.gapi.auth2.getAuthInstance()
      if (GoogleAuth.isSignedIn.get()) {
        const profile = GoogleAuth.currentUser.get().getBasicProfile()
        const authResponse = GoogleAuth.currentUser.get().getAuthResponse()
        this.$store
          .dispatch('account/registerBySosmed', {
            // eslint-disable-next-line @typescript-eslint/camelcase
            provider_token: authResponse.access_token,
            // eslint-disable-next-line @typescript-eslint/camelcase
            provider_name: 'google',
            email: profile.getEmail(),
            password: '1234567',
            // eslint-disable-next-line @typescript-eslint/camelcase
            phone_number: null,
            // eslint-disable-next-line @typescript-eslint/camelcase
            first_name: profile.getGivenName(),
            // eslint-disable-next-line @typescript-eslint/camelcase
            last_name: profile.getFamilyName()
          })
          .then(() => {
            this.$router
              .push(this.$route.query.redirect !== undefined ? this.$route.query.redirect : '/')
              .then(() => {
                // location.reload()
              })
            swal.close()
            swal.hideLoading()
          })
          .catch(error => {
            if (error.status == 400) {
              if (error.data.errors == 'Email atau No.Hp telah terdaftar, silahkan login') {
                this.$store
                  .dispatch('account/loginWithGoogle', {
                    username: profile.getEmail(),
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    provider_token: profile.getId()
                  })
                  .then(() => {
                    // this.isLoading = false
                    this.$router
                      .push(
                        this.$route.query.redirect !== undefined ? this.$route.query.redirect : '/'
                      )
                      .then(() => {
                        // location.reload()
                      })
                    swal.close()
                    swal.hideLoading()
                  })
                  .catch(error => {
                    swal.close()
                    swal.hideLoading()
                    if (error.response.status == 401) {
                      swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Terjadi kesalahan'
                      })
                    }
                  })
              }
            }
          })
      } else {
        GoogleAuth.signIn().then(() => {
          const profile = GoogleAuth.currentUser.get().getBasicProfile()
          const authResponse = GoogleAuth.currentUser.get().getAuthResponse()
          
          this.$store
            .dispatch('account/registerBySosmed', {
              // eslint-disable-next-line @typescript-eslint/camelcase
              provider_token: authResponse.access_token,
              email: profile.getEmail(),
              password: '1234567',
              // eslint-disable-next-line @typescript-eslint/camelcase
              provider_name: 'google',
              // eslint-disable-next-line @typescript-eslint/camelcase
              phone_number: null,
              // eslint-disable-next-line @typescript-eslint/camelcase
              first_name: profile.getGivenName(),
              // eslint-disable-next-line @typescript-eslint/camelcase
              last_name: profile.getFamilyName()
            })
            .then(() => {
              this.$router
                .push(this.$route.query.redirect !== undefined ? this.$route.query.redirect : '/')
                .then(() => {
                  // location.reload()
                })
              swal.close()
              swal.hideLoading()
            })
            .catch(error => {
              if (error.status == 400) {
                if (error.data.errors == 'Email atau No.Hp telah terdaftar, silahkan login') {
                  this.$store
                    .dispatch('account/loginWithGoogle', {
                      username: profile.getEmail(),
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      provider_token: profile.getId()
                    })
                    .then(() => {
                      // this.isLoading = false
                      this.$router
                        .push(
                          this.$route.query.redirect !== undefined
                            ? this.$route.query.redirect
                            : '/'
                        )
                        .then(() => {
                          // location.reload()
                        })
                      swal.close()
                      swal.hideLoading()
                    })
                    .catch(error => {
                      swal.close()
                      swal.hideLoading()
                      if (error.response.status == 401) {
                        swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: 'Terjadi kesalahan'
                        })
                      }
                    })
                }
              }
            })
        })
      }
    },
    handleSignUpFacebook() {
      swal.fire({
        title: '', // add html attribute if you want or remove
        text: 'Mohon Menunggu',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: function() {
          swal.showLoading()
        }
      })

      this.$store
        .dispatch('account/registerWithFacebook')
        .then(() => {
          swal.close()
          swal.hideLoading()
          this.$router
            .push(this.$route.query.redirect !== undefined ? this.$route.query.redirect : '/')
            .then(() => {
              // location.reload()
            })
        })
        .catch(() => {
          swal.close()
          swal.hideLoading()
        })
    }
  }
}
</script>
